import React from 'react';
import * as ReactGA from 'react-ga';
import Layout from '../components/Shared/Layout';
import MainSection from '../components/Home/main';
import ClientLogos from '../components/Shared/ClientLogos/index';
import Journey from '../components/Home/journey';
// import Expertise from '../components/Home/expertise';
import VerifiedReviews from '../components/Home/verified_reviews';
// import Slider2 from '../components/Home/Slider2';
// import Covid19 from "../components/Home/Covid";
// import CoreServices from '../components/Home/CoreServices';
import EngagementModels from '../components/Home/engagement_models';
import Partners from '../components/Home/partners';
import PrefetchContactForm from '../components/Shared/PrefetchContactForm';
import AwardsAndRecognition from '../components/Home/AwardsAndRecognition';
import withUTMParams from '../components/HOC/withUTMParams';
import BlogPostsSection from '../components/Shared/Section/BlogPostsSection/BlogPostsSection';
import useGoogleAnalyticsPageview from '../hooks/useGoogleAnalyticsPageview';
import FeaturedProjects from '../components/Home/FeaturedProjects';

const $ = typeof window !== `undefined` ? require('jquery') : null;

const responsiveValue = {
  lg: {
    breakpoint: {
      max: Number.MAX_SAFE_INTEGER,
      min: 1201,
    },
    items: 3,
    partialVisibilityGutter: 40,
    slidesToSlide: 3,
  },
  md: {
    breakpoint: {
      max: 1200,
      min: 769,
    },
    items: 2,
    partialVisibilityGutter: 30,
    slidesToSlide: 2,
  },
  sm: {
    breakpoint: {
      max: 768,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
    slidesToSlide: 1,
  },
};

function MainPage({location, pageContext}) {
  useGoogleAnalyticsPageview('Homepage');

  return (
    <>
      <PrefetchContactForm />
      <Layout location={location} footerProps={{showPortfolioSection: false}}>
        <MainSection variant={pageContext.variant} />
        <ClientLogos lazyLoading={false} />
        {/* <Covid19 /> */}
        <Journey />

        <FeaturedProjects />
        {/* <Slider2 /> */}
        {/* <CoreServices /> */}
        {/* <Expertise /> */}
        <VerifiedReviews />
        <EngagementModels />
        <BlogPostsSection carouselResponsive={responsiveValue} />
        <Partners />
        <AwardsAndRecognition />
      </Layout>
    </>
  );
}

export default withUTMParams(MainPage);
