import React from 'react';
import AwardsAndRecognitionCard from '../Shared/AwardsAndRecognition/AwardsAndRecognitionCard';
import AwardsAndRecognitionCarousel, {
  AWARDS,
} from '../Shared/AwardsAndRecognition/AwardsAndRecognitionCarousel';

function AwardsAndRecognition() {
  return (
    <div className="awards-section">
      <div className="container">
        <div className="awards__heading">Awards &amp; Recognitions</div>
        <p className="awards__description">
          We’re proud to have picked these recognitions up throughout our journey. <br /> To us,
          they’re more than trophies on a shelf, they represent our commitment to bringing the very
          best to our clients and their products.
        </p>
      </div>
      <div className="awards__desktop-only">
        <AwardsAndRecognitionCarousel />
      </div>
      <div className="awards__mobile-only container mt-5">
        <div className="row">
          {AWARDS.map((award, i) => (
            <div key={i} className="col d-flex justify-content-center align-items-center mb-4">
              <AwardsAndRecognitionCard {...award} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AwardsAndRecognition;
