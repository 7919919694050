import React from 'react';
import {MDBAnimation} from 'mdbreact';
// import {navigate} from 'gatsby'; //import navigate from gatsby

export default () => (
  <>
    <section className="clutch-section py-5">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12">
            <MDBAnimation type="fadeInUp" reveal>
              <div className="heading mb-2">Client Reviews</div>
            </MDBAnimation>

            <MDBAnimation type="fadeInUp" delay="0.2s" reveal>
              <div className="row mt-4">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card h-100  border-0 bg-transparent">
                    <div className="title mb-0 pt-4 px-4 bg-white">Stick Sports</div>
                    <div className="card-body py-3 px-4 bg-white">
                      <blockquote className="description darkgrey">
                        "Tintash's contribution to the games' success is tangible, with one of the
                        app receiving 25 million downloads.
                        <br />
                        <br />
                        The team excels at general project management, Particularly meeting project
                        timelines and understanding particular business needs of their clients."
                      </blockquote>
                      <div className="ratings d-inline">
                        <div className="stars float-left ">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <b className="pl-2 latoBold">5.0</b>
                        </div>
                        <div className="float-right">
                          <a
                            target="_blank"
                            href="https://clutch.co/profile/tintash#review-261975"
                            className="btn btn-sm link btn-outline-primary"
                          >
                            Read More&nbsp;&nbsp;<i className="fa fa-arrow-right" />
                          </a>
                        </div>
                      </div>
                      <div className="arrow" />
                    </div>
                    <div className="card-footer credits p-2 bg-transparent border-0">
                      <div className="d-flex align-items-center">
                        <div className="py-3 px-0 text-white">
                          <img
                            className="rounded-circle client-icon"
                            src={require('../../assets/images/home-page/clutch/paul.png').default}
                            alt="Paul"
                          />
                        </div>
                        <div className="mb-0 pl-3 pt-2 text-white">
                          <span className="latoBold name">Paul Collins</span>
                          <br />
                          <span className="latoRegular title">CEO</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card h-100  border-0 bg-transparent ">
                    <div className="title mb-0 pt-4 px-4 bg-white">Ander</div>
                    <div className="card-body py-3 px-4 bg-white">
                      <blockquote className="description darkgrey">
                        "Tintash quickly understood our goals, constructively scoped the project,
                        and built a team to deliver.
                        <br />
                        <br />
                        Tintash was critical to our platform’s success. Our primary deliverable in
                        the early stages was to prove capacity and capability to our stakeholders.
                        With Tintash onboard, our designs quickly were realized, and we could
                        demonstrate the product’s speed and quality.&nbsp;"
                      </blockquote>
                      <div className="ratings d-inline">
                        <div className="stars float-left">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <b className="pl-2 latoBold">5.0</b>
                        </div>
                        <div className="float-right">
                          <a
                            target="_blank"
                            href="https://clutch.co/profile/tintash#review-908346"
                            className="btn btn-sm link btn-outline-primary "
                          >
                            Read More&nbsp;&nbsp;<i className="fa fa-arrow-right" />
                          </a>
                        </div>
                      </div>
                      <div className="arrow" />
                    </div>
                    <div className="card-footer credits p-2 bg-transparent border-0">
                      <div className="d-flex align-items-center">
                        <div className="py-3 px-0 text-white">
                          <img
                            className="rounded-circle client-icon"
                            src={
                              require('../../assets/images/services/mobile-development/ander-coo.png')
                                .default
                            }
                            alt="Ander"
                          />
                        </div>
                        <div className="mb-0 pl-3 pt-2 text-white">
                          <span className="latoBold name" style={{fontSize: '14px'}}>
                            Chris Toepker
                          </span>
                          <br />
                          <span className="latoRegular title">COO, Head of Product </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card h-100  border-0 bg-transparent">
                    <div className="title mb-0 pt-4 px-4 bg-white">Bed, Bath and Beyond</div>
                    <div className="card-body py-3 px-4 bg-white">
                      <blockquote className="description darkgrey">
                        "I am fond of the quality of their work and their willingness to be present
                        as a team member.
                        <br />
                        <br />
                        Tintash has proven to be a reliable and committed development partner. They
                        strive to deliver high-quality work on time. They are detail-oriented and
                        make a concerted effort to integrate with existing teams. Tintash’s
                        technical skill set is impressive, but their loyalty is most commendable."
                      </blockquote>
                      <div className="ratings d-inline">
                        <div className="stars float-left">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <b className="pl-2 latoBold ">5.0</b>
                        </div>
                        <div className="float-right">
                          <a
                            target="_blank"
                            href="https://clutch.co/profile/tintash#review-902004"
                            className="btn btn-sm link btn-outline-primary "
                          >
                            Read More&nbsp;&nbsp;<i className="fa fa-arrow-right" />
                          </a>
                        </div>
                      </div>
                      <div className="arrow" />
                    </div>
                    <div className="card-footer credits p-2 bg-transparent border-0">
                      <div className="d-flex align-items-center">
                        <div className="py-3 px-0 text-white">
                          <img
                            className="rounded-circle client-icon"
                            src={
                              require('../../assets/images/home-page/client-spotlight/amra.png')
                                .default
                            }
                            alt="Amra"
                          />
                        </div>
                        <div className="mb-0 pl-3 pt-2 text-white">
                          <span className="latoBold name">Amra Tareen</span>
                          <br />
                          <span className="latoRegular title">Head of Innovations</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </MDBAnimation>
          </div>
        </div>
      </div>
    </section>
  </>
);
