import React from 'react';
import {Link} from 'gatsby';
import {MDBAnimation} from 'mdbreact';

import Carousel from 'react-multi-carousel';
import ProjectCard from '../Shared/ProjectCard/ProjectCard';
import CustomDot from '../Shared/Carousel/CustomDot';

const carouselResponsive = {
  sm: {
    breakpoint: {
      max: 768,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 40,
    slidesToSlide: 1,
  },
};

// Projects
const PROJECTS = [
  {
    id: 'stacks',
    featuredImageSrc: 'stacks.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/stacks.png').default,
    projectDescription:
      'Stacks is an open-source network of decentralized apps and smart contracts built on Bitcoin. ',
    projectName: 'stacks',
  },
  {
    id: 'alexgo',
    featuredImageSrc: 'alexgo.jpg',
    logoSrc: require('../../assets/images/portfolio/projects/logos/alexgo.png').default,
    projectDescription:
      'ALEX is an open-source DeFi development company built on the Stacks Blockchain and modeled on the world’s financial markets.',
    projectName: 'alexgo',
  },
  {
    id: 'xverse',
    featuredImageSrc: 'xverse.jpg',
    logoSrc: require('../../assets/images/portfolio/projects/logos/xverse.png').default,
    projectDescription:
      'Through Xverse Wallet, users can get an onramp on crypto, send and receive digital assets, and connect to DeFi and other decentralized applications.',
    projectName: 'xverse',
  },
  {
    id: 'polkacity',
    featuredImageSrc: 'polkacity.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/polkacity.png').default,
    projectDescription:
      'World’s First NFT MultiChain Social Platform and Video Game. Its is an online virtual world where users socialize and show off their NFTs to the other players.',
    projectName: 'polkacity',
  },
  {
    id: 'raven',
    featuredImageSrc: 'raven.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/raven.png').default,
    projectDescription:
      'Raven is a platform for Real Estate Agents, by Real Estate Agents, who want to replace big brokerages taking huge money off Agent’s Leads and their commissions.',
    projectName: 'raven',
  },
  {
    id: 'bed-bath-beyond',
    caseStudyUrl: '',
    featuredImageSrc: 'bed-bath-beyond.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/bed-bath-beyond.png').default,
    projectDescription:
      "Bed Bath and Beyond's app lets end-users visualize products by virtually placing them in their own room, providing a personalized and interactive shopping experience.",
    projectName: 'bed-bath-beyond',
  },
  {
    id: 'decorist',
    featuredImageSrc: 'decorist.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/decorist.png').default,
    projectDescription:
      'Decorist’s innovative room builder application that allows designers to get photorealistic renders of rooms in record time.',
    projectName: 'decorist',
  },
  {
    id: 'all3d',
    featuredImageSrc: 'all3d.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/all3d.png').default,
    projectDescription:
      'A platform for creating and delivering 3D Models & Services to provide immersive visual experiences for online shopping and personalised services.',
    projectName: 'all3d',
  },
];

const FeaturedProjects = () => {
  const projectsUI = PROJECTS.map(project => (
    <div key={project.id} className="web-dev__our-work-project-container m-3">
      <ProjectCard
        {...project}
        featuredImageLoading="lazy"
        projectDescriptionClass="web-dev__our-work-project-description"
      />
    </div>
  ));
  return (
    <section className="featured-projects py-5 mx-auto">
      <div className="container py-5 py-xs-2">
        <div className="row justify-content-center align-items-center">
          <MDBAnimation type="fadeInUp" duration="1s" reveal>
            <div className="heading latoBlack">Featured Projects</div>
          </MDBAnimation>
        </div>
      </div>
      <MDBAnimation type="fadeInUp" duration="1s" reveal>
        <div className="d-sm-none">
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            // autoPlay
            autoPlaySpeed={6000}
            containerClass="container mb-5"
            customTransition="transform 800ms ease-out"
            transitionDuration={800}
            centerMode={false}
            className=""
            itemClass="featured-projects-carousel-item"
            draggable
            focusOnSelect={false}
            infinite
            keyBoardControl
            minimumTouchDrag={80}
            partialVisbile
            renderButtonGroupOutside={false}
            renderDotsOutside
            responsive={carouselResponsive}
            showDots
            customDot={<CustomDot color="#0ec47588" activeColor="#0ec475cc" />}
            slidesToSlide={1}
            swipeable
            ssr
          >
            {projectsUI}
          </Carousel>
        </div>
        <div className="d-none d-sm-block">
          <div className="project-card-wrapper mx-auto mb-5 mb-xs-2 d-flex flex-row justify-content-center flex-wrap">
            {projectsUI}
          </div>
        </div>
      </MDBAnimation>
      <div className="container mt-lg-4 pb-lg-2">
        <div className="row justify-content-center align-items-center">
          <MDBAnimation type="fadeInUp" duration="1s" reveal>
            <Link className="text-center btn btn-view-portfolio px-5" to="/portfolio">
              View Portfolio
            </Link>
          </MDBAnimation>
        </div>
      </div>
    </section>
  );
};
export default FeaturedProjects;
