import React from 'react';
import {navigate} from 'gatsby';
import {Helmet} from 'react-helmet';
import bigBanner from '../../assets/images/home-page/hero-banner-bg.jpg';
import {Event} from '../GAClickEvent';

export default ({variant = 'A'}) => (
  <>
    <Helmet>
      <title>Tintash - Stanford Alumni Led Web &amp; App Development Company</title>

      <meta
        name="description"
        content="Tintash is a custom software development company providing dedicated remote teams globally. We work closely with you to design and build your digital products. Our clients include Startups, Unicorns and Fortune 500s."
      />

      <link rel="preload" href={bigBanner} as="image" media="(min-width: 601px)" />
    </Helmet>

    <main className="main">
      <div className="container">
        <div className="heading-container-padding row d-flex justify-content-center align-items-center">
          <div className="col-12 col-md-10 col-lg-12 content-slide-top-container ">
            <div className="main-heading content-slide-top">
              We <strong className="main-heading__strong">Design</strong> &amp;
              <br />
              <strong className="main-heading__strong">Build Products</strong>
            </div>

            <div className="row section-divider-container">
              <div className="section-divider" />
            </div>

            <div className="row content-slide-top">
              <div className="col-lg-12">
                {variant === 'B' && (
                  <div className="description latoRegular">
                    <h1 className="landing-description__h1--span">
                      Tintash is a mobile and web development company providing dedicated remote
                      teams globally.
                    </h1>{' '}
                    <span>
                      We work closely with you to design and build your digital products. Our
                      clients include several <span className="latoBold">Startups</span>,{' '}
                      <span className="latoBold">Unicorns</span> and{' '}
                      <span className="latoBold">Fortune 500s</span>.
                    </span>
                  </div>
                )}
                {variant === 'A' && (
                  <div className="description latoRegular">
                    <span>
                      We are the first team for seed stage startups. We specialize in Metaverse and
                      Blockchain. Our clients include several{' '}
                      <span className="latoBold">Startups</span>,{' '}
                      <span className="latoBold">Unicorns</span> and{' '}
                      <span className="latoBold">Fortune 500s</span>.
                    </span>
                  </div>
                )}
              </div>
              <div className="col-lg-2 email-form main__signup">
                <div className="input-group mb-3">
                  <button
                    className="main_contact_button btn signup-btn btn-lg btn-success-home latoBold"
                    onClick={() => {
                      navigate('/contact');
                      Event('Contact Us', 'Contact Us button is clicked', window.location.pathname);
                    }}
                  >
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </>
);
